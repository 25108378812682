import axios from 'axios';
import PageLoader from 'components/atoms/Loader/PageLoader';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import CardSolution from 'components/molecules/Cards/CardSolution';
import parse from 'html-react-parser';
import { Fragment, useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { setTranslation } from 'redux/global/translationSlice';
import { setBreadcrumb } from 'redux/navigation/breadcrumbSlice';
import { setSidebar } from 'redux/navigation/sidebarSlice';
import useSWR from 'swr';
import { SuperSEO } from "react-super-seo";

const fetcher = async (url) => {
    const response = await axios({
        method: 'get',
        url: url
    })
    return response.data
}
const MainPage = ({ code, artObj}) => {
    const dispatch = useDispatch()
    const breadcrumb = useSelector(state => state.breadcrumb.value)
    const [page, setPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const URL = process.env.REACT_APP_API
    const loc = useLocation()
    const lang = reactLocalStorage.getObject("gs_lang").code
    const wording = useSelector(state => state.wording.value)
    const slug = loc.pathname.split("/").pop()
    const { data, isLoading } = useSWR(`${URL}/${lang}/partner/${slug}/page/${page}`, fetcher)
    const translation = data?.category?.translation
    useEffect(() => {
        document.title = isLoading? "Loading" : data?.category?.name;
        dispatch(setTranslation(translation && Object.values(translation)[0]))
        dispatch(setSidebar(data?.navigation?.sidebar || []))
        dispatch(setBreadcrumb(data?.navigation?.breadcrumb || []))
    }, [data])
    const countPage = data && Math.ceil(data?.pagination.total / data?.pagination.limit)

    const changePage = (item) => {
        //console.log(item)
        setCurrentPage(item)
        setPage(item)
    }
    const handleChevron = (item) => {
        if (item === "PREV") {
            setPage(page - 1)
            setCurrentPage(currentPage - 1)
        } else {
            setPage(page + 1)
            setCurrentPage(currentPage + 1)
        }
    }
    //console.log("artObj", artObj)
    return (
        <div>
            <SuperSEO
        description={data?.category?.description}
        />
            <Breadcrumbs data={breadcrumb} />
            <div className="flex flex-col">
            <div className='mb-12'>
                <h1 className='text-body-primary text-2xl mb-5'>
                    {data?.category?.name}
                </h1>
                <article>
                    {parse(data?.category?.description || "")}
                </article>
            </div>
            <div>
                <h2 className='text-body-primary text-2xl mb-5'>Solution Partner</h2>
                <div className="grid gap-5">
                    {
                        isLoading && <PageLoader type="article" />
                    }
                    {
                        data?.list?.map(item => (
                            <CardSolution
                                navigation={data?.navigation}
                                mainTrans={data?.category?.translation}
                                data={item}
                                wording={wording}
                                key={item.id}
                                image={item.image}
                                url={item.slug}
                                title={item.name}
                                info={item.overview}
                            />
                        ))
                    }
                </div>
            </div>
            </div>
            {
                countPage && (
                    <div className="flex gap-8 flex-row justify-end mt-5 text-xl text-[#888] font-semibold">

                        {currentPage > 1 ? (
                            <button
                                onClick={() => handleChevron("PREV")}
                            >
                                <FaChevronLeft />
                            </button>
                        ) : (
                            <button disabled>
                                <FaChevronLeft />
                            </button>
                        )}
                        {
                            [...Array(countPage).keys()].map(item => (
                                <Fragment key={item}>
                                    {
                                        item + 1 <= 3 && (
                                            <button
                                                onClick={() => changePage(item + 1)}
                                                className={`text-xl ${currentPage === item + 1 ? "text-[#212] " : "text-[#888] "}font-semibold`}
                                            >
                                                {item + 1}
                                            </button>
                                        )
                                    }
                                </Fragment>
                            ))
                        }
                        {
                            countPage > 3 && (
                                <>
                                    <div>...</div>
                                    <button>
                                        {countPage}
                                    </button>
                                </>
                            )
                        }
                        {
                            currentPage < countPage ? (
                                <button
                                    onClick={() => handleChevron("NEXT")}
                                >
                                    <FaChevronRight />
                                </button>
                            ) : (
                                <button disabled>
                                    <FaChevronRight />
                                </button>
                            )
                        }
                    </div>
                )
            }

        </div>
    )
}

export default MainPage